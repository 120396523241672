





























































































































import Vue from 'vue';
import { mdiOpenInNew } from '@mdi/js';
export default Vue.extend({
  name: 'Welcome',
  data() {
    return {
      mdiOpenInNew
    }
  }

});
